body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

a{
  text-decoration: none;
}

.switch {
  display: inline-block;
  height: 14px !important;
  position: relative;
  width: 40px !important;
}

.switch input {
  display:none;
}

.slider {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
  background-color: #aaa;
}

.slider:before {
  box-shadow: 0 0 8px #00000055;
  background-color: #fefefe;
  bottom: -4px;
  content: "";
  height: 21px;
  left: 0;
  position: absolute;
  transition: .4s;
  width: 21px !important;
}

input:checked + .slider {
  background-color: #0A5282;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}